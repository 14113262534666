import { useState, useEffect, useRef } from 'react';
import { useSpring, useTransition, animated } from '@react-spring/web';
import './App.css';
import QRCode from "react-qr-code";
import axios from 'axios';
import * as html2canvas from 'html2canvas'
import { isMobile, browserName, osName, deviceType, isIOS13, isChrome  } from 'react-device-detect'
import download from 'downloadjs';
import { toPng } from 'html-to-image';
import imgHeader from './assets/header.png'
import imgHeaderFinal from './assets/headerFinal.png'
import coracaoOff from './assets/coracaoOff.png'
import coracaoOn from './assets/coracaoOn.png'
import balazinho from './assets/balaonovo.png'
import pagtoPix from './assets/pagtoPix.png'
import copyPaste from './assets/copyPaste.png'
import pixBoto from './assets/copiarPix.png'
import gerarPix from './assets/gerarPix.png'
import pixAguarde from './assets/pixAguarde.png'
import linhaBaixo from './assets/linhaBaixo.png'
import baixar from './assets/dowload.png'
import compartilhar from './assets/compartilhe.png'
import responder from './assets/responder.png'
import maiseDezoito from './assets/mais18.png'
import downloadOk from './assets/DownloadOk.png'




function App() {
  const [link, setLink] = useState(false)  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [balao, setBalao] = useState(false)
  const [value, setValue] = useState('')
  const [startCustomer, setStartCustomer] = useState(false)
  const [cobrancaId, setCobrancaId] = useState('')
  const [startCobranca, setStartCobranca] = useState(false)
  const [status, setStatus] = useState('')
  const [isRunning, setIsRunning] = useState(false)
  const [timeout, setTimeout] = useState(false)
  const [counter, setCounter] = useState(0);
  const [final, setFinal] = useState(false)
  const [pix, setPix] = useState(false)
  const [salvarPrint, setSalvarPrint] = useState(false)
  const [timer, setTimer] = useState(5 * 60);
  const [barra, setBarra] = useState(0)
  const [imageFicha, setImageFicha] = useState()
  const [fodase, setFodase] = useState(false)
  const [responderImg, setResponderImg] = useState(false)
  const [mais18, setMais18] = useState(true)
  const [download, setDownload] = useState(false)
  const fichaRef = useRef();
  const userAgent = navigator.userAgent
  
  //const url = 'http://localhost:3000'
  const url = 'https://fichadoamor-backend.vercel.app'


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      };
  }, []);

  const updatePergunta = (index, newValue) => {
    setPerguntas(prevPerguntas => {
      const updatedPerguntas = [...prevPerguntas]; // Cria uma cópia da matriz de perguntas existente
      updatedPerguntas[index] = { ...updatedPerguntas[index], ...newValue }; // Atualiza os valores da pergunta específica
      return updatedPerguntas; // Retorna a nova matriz de perguntas atualizada
    });
  };

  const [perguntas, setPerguntas] = useState([
    {pergunta: 'Compartilha fotos íntimas com conforto?', linha: 0, pos: 0, clicked: false},
    {pergunta: 'Valoriza beijos e contato físico?', linha: 0, pos: 0, clicked: false},
    {pergunta: 'Preliminares são importantes para você?', linha: 0, pos: 0, clicked: false},
    {pergunta: 'Você está aberto(a) a explorar a sua sexualidade?', linha: 0, pos: 0, clicked: false},
    {pergunta: 'Gosta de diversas posições na intimidade?', linha: 0, pos: 0, clicked: false},
    {pergunta: 'Aprecia receber beijos nas partes íntimas?', linha: 0, pos: 0, clicked: false},
    {pergunta: 'Se sente à vontade em demostrar afeto ao parceiro da mesma maneira?', linha: 0, pos: 0, clicked: false},
    {pergunta: 'Discutiria uma idéia de troca de casais?', linha: 0, pos: 0, clicked: false},
    {pergunta: 'Como vê gestos afetuosos mais intensos, como tapas leves?', linha: 0, pos: 0, clicked: false},
    {pergunta: 'Você se sente bem em experimentar novas formas de estímulo?', linha: 0, pos: 0, clicked: false},
    {pergunta: 'Sente-se à vontade com intimidade hardcore? como anal, por exemplo?', linha: 0, pos: 0, clicked: false},
    {pergunta: 'Curte o tal 69?', linha: 0, pos: 0, clicked: false},
    {pergunta: 'Consideraria uma experiência com mais de duas pessoas?', linha: 0, pos: 0, clicked: false},
    {pergunta: 'Você se sente confortável com as relações que façam parte da sigla LGBTQIA+?', linha: 0, pos: 0, clicked: false},
    {pergunta: 'Prefere relações tradicionais?', linha: 0, pos: 0, clicked: false},
])

const contarClickedTrue = (perguntas) => {
  let contador = 0;

  for (const pergunta of perguntas) {
    if (pergunta.clicked) {
      contador++;
    }
  }

  return contador;
};

const quantidadeClickedTrue = contarClickedTrue(perguntas)

useEffect(()=>{
  if(quantidadeClickedTrue === 15) {
    setLink(true)
  }
}, [quantidadeClickedTrue])


const headersDefault = {
  headers: {
    "Content-Type": "application/json"
  }}

async function CriarCobranca() {
  console.log('cobranca iniciado')
  await axios.post(url+'/api/cobranca', headersDefault)
      .then(response => {
          setCobrancaId(response.data.idCobranca)
          setValue(response.data.qrcode)
          console.log(response.data)
          setStartCobranca(false)
          setIsRunning(true)
      })
      .catch(e => {
          console.log('Erro: ', e);
      })
  }


  async function ConsultarCobranca() {
    console.log('Consultando a cobrança')
    await axios.post(url+`/api/consulta?cobrancaId=${cobrancaId}`, headersDefault)
        .then(response => {
            setStatus(response.data.status)
            console.log('Status: '+response.data.status)
        })
        .catch(e => {
            console.log('Erro: ', e);
        })
  } 


  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isRunning) {
        console.log('iniciando a consulta no useEffect')
        ConsultarCobranca()
        setCounter(prevCounter => prevCounter + 1);
      }
    }, 10 * 1000);

    return () => clearInterval(intervalId);
  }, [isRunning]);

  useEffect(()=>{
    if(status === 'RECEIVED') {
        setFinal(true)
        setBalao(false)
        setIsRunning(false)
        setSalvarPrint(true)
        setLink(false)
    }
  }, [status])

  function RestartAction() {
    setValue('')
    setFinal(false)
    setTimeout(false)
    setStatus()
    setCounter(0)
    setBarra(0)
    setTimer(5 * 60)
    setCobrancaId()
    CriarCobranca()
  }

    console.log(barra)
  
    useEffect(() => {
      if(balao) {
        const interval = setInterval(() => {
          if (timer > 0) {
            setTimer(timer - 1);
          }
          setBarra(prevBarra => prevBarra + 1)
        }, 1000);
        return () => clearInterval(interval)
      }
    }, [timer, balao])
  
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;

    useEffect(()=>{
      if(barra > 300) {
        setTimeout(true)
      }

    }, [barra])
    useEffect(()=>{
      if(barra > 305) {
        setIsRunning(false)
      }
    }, [barra])


    function generateImage() {
      const node = document.getElementById('fichaDiv');

      const fichaDiv = fichaRef.current;
      html2canvas(fichaDiv).then(canvas => {
        const link = document.createElement('a');
        link.download = 'fichadoamor.png';
        link.href = canvas.toDataURL('image/png');
        link.click();
      }).catch(error => {
        console.error('Erro ao capturar a imagem:', error);
      });
        
    }



  const CopyImageToClipboard = (base64String) => {
    const img = new Image();
    img.src = base64String;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;
  
    ctx.drawImage(img, 0, 0);
    canvas.toBlob((blob) => {
      const data = new ClipboardItem({ 'image/png': blob });
      navigator.clipboard.write([data])
        .then(() => {
          console.log('Image copied to clipboard successfully');
        })
        .catch((error) => {
          console.error('Error copying image to clipboard:', error);
        });
    }, 'image/png');
  };

  const handleCopyImage = () => {
    CopyImageToClipboard(imageFicha);
  };

  useEffect(() => {
    const intervalMais18 = setInterval(() => {
        setMais18(false)
    }, 5 * 1000);
    return () => clearInterval(intervalMais18);
  }, []);

  const handleShare = async () => {
    
          const node = document.getElementById('fichaDiv');
      
          try {
            // Converte a div para uma imagem usando html2canvas
            const canvas = await html2canvas(node);
            const dataUrl = canvas.toDataURL('image/png');
      
            // Converte a dataURL para um arquivo Blob
            const response = await fetch(dataUrl);
            const blob = await response.blob();
      
            // Verifica se a Web Share API está disponível
            if (navigator.share && navigator.canShare && navigator.canShare({ files: [new File([blob], 'ficha.png', { type: blob.type })] })) {
              await navigator.share({
                title: 'Minha Imagem',
                text: 'Confira esta imagem!',
                url: 'https://fichadoamor.com.br',
                files: [new File([blob], 'ficha.png', { type: blob.type })],
              });
              alert('Imagem compartilhada com sucesso!');
              setResponderImg(true)
            } else {
              alert('Compartilhamento de arquivos não é suportado neste navegador. Baixando a imagem...');
              // Fallback para baixar a imagem
              const link = document.createElement('a');
              link.href = dataUrl;
              link.download = 'ficha.png';
              link.click();
            }
          } catch (error) {
            console.error('Erro ao compartilhar a imagem', error);
            alert('Ocorreu um erro ao compartilhar a imagem.');
          }
  }

  return (
    
    <div className="App">
      
      {balao ?
      <div style={{display: 'flex', width: windowWidth, height: windowHeight, justifyContent: 'center', backgroundColor: '#ffffff'}}>
        <a id="qr"></a>
        <div className='boxCellBco' style={{alignItems: 'flex-start'}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: 380}}>  
                  {timeout ? 
                  <div className='txtComum' style={{height: 155, width: 155, backgroundColor: '#ebebeb', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    QRCode<br />expirado
                  </div>
                  : 
                  value != '' ?
                  <QRCode
                    size={145}
                    bgColor='#000000'
                    fgColor='#ffffff'
                    style={{ 
                        height: "auto", 
                        maxWidth: "155px", 
                        width: "155px", 
                    }}
                    value={value}
                    viewBox={`0 0 256 256`}
                    />
                    :
                    <img src={pixAguarde} width={155} height={155} /> 
                  }
                  <img src={pagtoPix} style={{marginTop: 15, marginBottom: 15}} width={220} />




                  <div className='txtComum' style={{width: 280, marginTop: 10, color: '#808080', lineHeight: 1.3, fontSize: 17}}>
                    Copie o código acima e utilize o Pix Copia e Cola no aplicativo que você vai fazer o pagamento.
                  </div>


                  <button className='copyPaste' onClick={timeout ? null : () => {navigator.clipboard.writeText(value);setPix(true)}}>
                    <div className='pixBox'>
                      <div className='pixTxt'>
                        {value === '' ? '' : timeout ? 'QRCode expirado' : pix ? 'PIX copiado!' : '00020101021226820014br.gov.bcb...'}
                      </div>  
                        <img src={copyPaste} />
                    </div>
                  </button>
                  <div style={{height: 100, width: 250, display: 'flex', alignItems: 'center'}}>
                    {timeout ? 
                      <button className='copyPaste' onClick={() => {RestartAction()}}>
                        <img src={gerarPix} width='220' />
                      </button>
                    :
                      value != '' ? <button className='copyPaste' onClick={() => {navigator.clipboard.writeText(value); setPix(true)}}>
                        <img src={pixBoto} width='220' />
                      </button> : null
                    }
                  </div>
                  {timeout ? 
                    <div className='txtComum' style={{width: 280, color: 'red', lineHeight: 1.3, fontSize: 17, height: 100}}>
                      O tempo para pagamento do QRCode expirou. Você pode gerar um novo.
                    </div>
                  : null}

                  <div className='txtComum' style={{width: 330, color: 'red', fontWeight: 'bolder', lineHeight: 1.3, fontSize: 24, marginBottom: 20, marginTop: -20}}>
                    ATENÇÃO:<br />Não feche esta tela.
                  </div>                  
                  <div className='txtTime' style={{width: 330, color: '#000000', fontSize: 17, textAlign: 'left'}}>
                    {minutes < 10 ? '0' : ''}{timeout ? '0' : minutes}:{seconds < 10 ? '0' : ''}{timeout ? '0' : seconds}
                  </div>
                  
                  <div className='timeBack'>
                    <div className='timeFront' style={{width: timeout ? 330 : barra, maxWidth: 330}}>
                    </div>


                  </div>


                  {/*}
                    <button onClick={()=>{setStatus('RECEIVED')}}>
                      simular pagamento {status}
                    </button>
                  */}
                  
                </div>
                 
              
        </div>
      </div>
    : 
    <div id='fichaDiv' ref={fichaRef}>
      <div style={{display: 'flex', height: 100, backgroundColor: '#d81b1b', justifyContent: 'center', alignItems: 'center'}}>
        <img src={salvarPrint ? imgHeaderFinal : imgHeader} width={295} height={64} style={{marginTop: 5, marginBottom: 5, width:295, height: 64}} />
      </div>

      <div className="boxCell" style={{width: windowWidth-20}}>
      {mais18 ?
        <div className='balaozinho' style={{top: 400, left: isChrome && isIOS13 ? (windowWidth/2)-130 : (windowWidth/2)-150}}>
          <img src={maiseDezoito} width={isChrome && isIOS13 ? 260 : 300} height={'auto'} />
        </div>
      : null}
      {download ? 
        <div>
          <img src={downloadOk} width={300} height={'auto'} className='maisDezoito' />
        </div>
      : null }
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <div className='perguntaTit' style={{display: 'flex', flex: 1, width: 190, alignItems: 'center', justifyContent: 'center'}}>
              QUESTIONÁRIO PARA CASAIS
            </div>
            <div className="quadradinhoTit">
              SIM
            </div>
            <div className="quadradinhoTit">
              TALVEZ
            </div>
            <div className="quadradinhoTit">
              NÃO
            </div>
            {/*
            <div className="quadradinhoTit">
              ODEIO
            </div>
            */}
          </div>
          
          {link ?   
            <button className='balaozinho' style={{top: 400, left: isChrome && isIOS13 ? (windowWidth/2)-130 : (windowWidth/2)-175}} onClick={()=>{CriarCobranca(); setBalao(true); setLink(false)}}>
                <img src={balao ? null : balazinho}  width={isChrome && isIOS13 ? 260 : 350} height={'auto'} />
            </button>
          : null}
          
        {perguntas.map((perg, index)=>(
          <div key={index} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <div className='pergunta' style={{textAlign: 'center', display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center',}}>
              {perg.pergunta}
            </div>
            <button onClick={() => updatePergunta(index, { pos: 1, clicked: true })} className="quadradinho">
              <img src={perg.pos === 1 ? coracaoOn : coracaoOff} className="coracao"/>
            </button>
            <button onClick={() => updatePergunta(index, { pos: 2, clicked: true })} className="quadradinho">
              <img src={perg.pos === 2 ? coracaoOn : coracaoOff} className="coracao" />
            </button>
            <button onClick={() => updatePergunta(index, { pos: 3, clicked: true })} className="quadradinho">
              <img src={perg.pos === 3 ? coracaoOn : coracaoOff} className="coracao" />
            </button>
            {/*}
            <button onClick={() => updatePergunta(index, { pos: 4, clicked: true })} className="quadradinho">
              <img src={perg.pos === 4 ? coracaoOn : coracaoOff}  className="coracao" />
            </button>
            */}
          </div>
        ))}
        
        {salvarPrint ?
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', backgroundColor: '#d81b1b', border: 0}}>
            {windowWidth > 600 ?
              <button onClick={()=>{setResponderImg(true); generateImage(); setDownload(true)}} className='txtTime' style={{border: 'none', backgroundColor: 'transparent', width: 364, marginTop: -50, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', backgroundColor: '#d81b1b'}}>
                <img src={baixar} width={310} height={'auto'} /> 
              </button>
            :
              <button onClick={handleShare} className='txtTime' style={{border: 'none', backgroundColor: 'transparent', width: windowWidth, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', backgroundColor: 'red'}}>
                <img src={compartilhar} width={310} height={'auto'} /> 
              </button>
            }
          </div>
        : 
          balao ? null : 
            <button onClick={()=>{setLink(true)}} style={{display: 'flex', justifyContent: 'center', backgroundColor: '#d81b1b', border: 0}}>
              <img src={linhaBaixo} width={320} style={{marginTop: 5, marginBottom: 20, width: 320, height: 44}}  />
            </button>
        }

      {/*salvarPrint ?
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', backgroundColor: '#ff0000',}}>
          
            <button className='txtTime' style={{border: 'none', backgroundColor: 'transparent', marginTop: 10, display: 'flex', alignItems: 'center',}}>
              <img src={responder} width={270} height={'auto'} />
            </button>
    
        </div>
      : null */}

      </div>
      
    </div>
    }

    </div>
      
  );
}

export default App;
